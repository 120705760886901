export const reviews = [
  {
    id: 1,
    name: 'Muhammed Abubakar',
    location: 'Auchi, Edo State',
    text: "I have been a customer of suffy's water for 5years+. They've never disappointed me once.",
  },
  {
    id: 2,
    name: 'Rejoice Sammuel',
    location: 'Aviele, Edo State.',
    text: ' My Customers dey always ask for one water and na suffy water. they say e clean, no get smell, no get taste and e good for their body',
  },
  {
    id: 3,
    name: 'Aminat Isah',
    location: ' Agbede, Edo State.',
    text: ' Since the introduction of their online service, i worry less about getting water now. I just place order online and wait for them to deliver to me. And they are always fast',
  },
  {
    id: 5,
    name: 'Maimunat Oshoke',
    location: 'Auchi, Edo State',
    text: ' Their drivers are among they best. they have good customer relationship',
  },
];
